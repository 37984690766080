import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						隔測式温度計（注文製作品：防滴型）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
						<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lb-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・壁掛型 LB-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lb-150s.pdf" target={"_blank"} rel="noreferrer">
									防滴・壁掛型 LB-150S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lb-75s.pdf" target={"_blank"} rel="noreferrer">
									防滴・壁掛型 LB-75S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/ld-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・埋込型 LD-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/ld-75s.pdf" target={"_blank"} rel="noreferrer">
									防滴・埋込型 LD-75S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/las-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・直結型 LAS-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lal-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・直結型 LAL-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lat-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・直結型 LAT-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/laf-100s.pdf" target={"_blank"} rel="noreferrer">
									防滴・直結型 LAF-100S
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
